<template>
    <div class="footer__column">
        <div v-if="title" class="footer__column__title" @click="toggle">
            {{ title }}
            <VJoyIcon :name="isOpen ? 'chevron-up' : 'chevron-down'" class="footer__column__title__chevron" />
        </div>
        <div ref="contentRef" :style="{maxHeight: isOpen ? contentHeight + 'px' : '0px'}" class="footer__column__content">
            <ul class="footer__column__list">
                <li v-for="(item, i) in slice.items" :key="i">
                    <PrismicRichText :field="item.link" :link-resolver="linkResolver" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {ref, computed, nextTick} from 'vue';
    import {getSliceComponentProps, PrismicRichText} from '@prismicio/vue';
    import type {LinksColumnSliceSlice} from '@prismicio-types';
    import {VJoyIcon} from '@maltjoy/core-vue';
    import {linkResolver} from '@navbar-unlogged/helpers';

    const props = defineProps(getSliceComponentProps<LinksColumnSliceSlice>());
    const title = ref((props.slice.primary.title[0] as {text: string}).text);
    const links = ref(
        props.slice.items
            .map((item) => {
                const link = item.link[0] as any;
                const data = link?.spans[0]?.data;
                if (data) {
                    const doc = {lang: '', type: data.type, uid: data.uid};
                    const url = data.uid ? linkResolver(doc) : data.url;
                    return {
                        url,
                        label: link.text,
                        external: /^https?:\/\//.test(url),
                    };
                }
                return null;
            })
            .filter((link) => link !== null),
    );

    const isOpen = ref(false);
    const contentHeight = ref(0);
    const contentRef = ref<HTMLElement | null>(null);
    const toggle = () => {
        isOpen.value = !isOpen.value;
        nextTick(() => {
            contentHeight.value = contentRef.value!.scrollHeight;
        });
    };
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .footer__column {
        &__title {
            font-family: var(--joy-font-family);
            font-size: var(--joy-font-size-primary-600);
            color: var(--joy-color-secondary-30);
            font-weight: var(--joy-font-weight-bold);
            line-height: var(--joy-line-height-small);
            margin-bottom: var(--joy-core-spacing-6);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;

            @include mq.screen_md(min) {
                cursor: default;
                &__chevron {
                    display: none;
                }
            }
        }

        &__content {
            overflow: hidden;
            transition: max-height var(--joy-transition-duration-long) var(--joy-transition-timing-function);

            @include mq.screen_md(min) {
                overflow: visible;
                max-height: none !important;
            }
        }
        &__list {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: var(--joy-core-spacing-4);
        }

        a {
            font-size: var(--joy-font-size-primary-400);
            line-height: var(--joy-line-height-small);
            color: var(--joy-color-neutral-50);
            position: relative;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }

            &:after {
                background: none repeat scroll 0 0 transparent;
                bottom: -3px;
                content: '';
                display: block;
                height: 1px;
                left: 50%;
                position: absolute;
                background: var(--joy-color-neutral-50);
                transition: width var(--joy-transition-duration-default) var(--joy-transition-timing-function),
                    left var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                width: 0;
            }

            &:hover:after {
                width: 100%;
                left: 0;
            }
        }
    }
</style>
